
$(document).ready(function () {

    // Page slide logic start //

    var slides = $('.content-block');

    slides.first().before(slides.last());
    $('.last-slide').html($('.content-block').length);

    $('.arrow').on('click', function () {
        slides = $('.content-block');
        var button = $(this);
        var activeSlide = $('.active');

        if (button.attr('id') == 'next') {
            slides.last().after(slides.first());

            activeSlide.removeClass('active').next('.content-block').addClass('active');
            $('.curent-slide').html($('.content-block.active').attr('data-val'));
            var currActiveData = $('.content-block.active').attr('data-val');
            if (currActiveData == 2 || currActiveData == 4 || currActiveData == 6) {
                setTimeout(function () {
                    $('.header-section').addClass('header-black');
                    $('.footer-section').addClass('footer-black');
                }, 500)

            } else {
                setTimeout(function () {
                    $('.header-section').removeClass('header-black');
                    $('.footer-section').removeClass('footer-black');
                }, 500)
            }
            $('.arrows').find('.is-loaded').addClass('loaded');
            setTimeout(function () {
                $('.arrows').find('.is-loaded').removeClass('loaded');
            }, 700)

            $(window).width(function () {
                var width = $(document).width();
                if (width >= 991) {
                    if ($('.consultation').hasClass('active')) {
                        $('.consult-link-desctop').hide();
                    } else {
                        $('.consult-link-desctop').show();
                    }
                }
                else {
                    if ($('.consultation').hasClass('active')) {
                        $('.consult-link-mobile').hide();
                    } else {
                        $('.consult-link-mobile').show();
                    }

                }

            });
            document.querySelectorAll('.nav-links-wrapper a').forEach(function (el) {
                if ($('.content-block.active').attr('data-val') == el.dataset.nav) {
                    el.classList.add('nav-active');
                } else {
                    el.classList.remove('nav-active');
                }
            });
        }
        if (button.attr('id') == 'prev') {
            slides.first().before(slides.last());
            activeSlide.removeClass('active').prev('.content-block').addClass('active');
            $('.curent-slide').html($('.content-block.active').attr('data-val'));
            var currActiveData = $('.content-block.active').attr('data-val');
            if (currActiveData == 2 || currActiveData == 4 || currActiveData == 6) {
                setTimeout(function () {
                    $('.header-section').addClass('header-black');
                    $('.footer-section').addClass('footer-black');
                }, 500)

            } else {
                setTimeout(function () {
                    $('.header-section').removeClass('header-black');
                    $('.footer-section').removeClass('footer-black');
                }, 500)
            }
            $('.arrows').find('.is-loaded').addClass('loaded');
            setTimeout(function () {
                $('.arrows').find('.is-loaded').removeClass('loaded');
            }, 700)

   
            $(window).width(function () {
                var width = $(document).width();
                if (width >= 991) {
                    if ($('.consultation').hasClass('active')) {
                        $('.consult-link-desctop').hide();
                    } else {
                        $('.consult-link-desctop').show();
                    }
                }
                else {
                    if ($('.consultation').hasClass('active')) {
                        $('.consult-link-mobile').hide();
                    } else {
                        $('.consult-link-mobile').show();
                    }

                }

            });
            document.querySelectorAll('.nav-links-wrapper a').forEach(function (el) {
                if ($('.content-block.active').attr('data-val') == el.dataset.nav) {
                    el.classList.add('nav-active');
                } else {
                    el.classList.remove('nav-active');
                }

            });
        }
    });

    $('.nav-menu-btn-wrapp').on('click', function () {
        $('.navigation-menu-block').fadeIn();
    })
    $('.exit-cross-wrap').on('click', function () {
        $('.navigation-menu-block').fadeOut();
    })
    $('.nav-links-wrapper a').on('click', function () {
        var currNavLink = Number($(this).attr('data-nav'));
        var prevActiveSlide = Number($('.content-block.active').attr('data-val'));
        document.querySelectorAll('.content-block').forEach(function (el) {
            var clickCount;
            var currSlide = Number(el.dataset.val);
            if (currNavLink === currSlide) {

                if (prevActiveSlide < currSlide) {
                    clickCount = currSlide - prevActiveSlide;
                    (function getNewSlide() {
                        if (clickCount == 0) return;
                        $('#next').click();
                        clickCount--;
                        getNewSlide();
                    })();
                } else if (prevActiveSlide > currSlide) {
                    clickCount = prevActiveSlide - currSlide;
                    (function getNewSlide() {
                        if (clickCount == 0) return;
                        $('#prev').click();
                        clickCount--;
                        getNewSlide();
                    })();
                }
            }
        });
        $('.nav-links-wrapper a').removeClass('nav-active');
        $(this).addClass('nav-active');
        $('.navigation-menu-block').fadeOut();

    })

    $('.header-logo-desktop , .header-logo-mobile').on('click', function () {
        document.location.reload();
    })
    $('.online-consult').on('click', function () {
        var currActiveSlide = $('.content-block.active').attr('data-val')
        console.log(currActiveSlide, '5555')
        var toConsultLink = 5;
        var goToThisSlide;
        if (currActiveSlide < toConsultLink) {
            goToThisSlide = toConsultLink - currActiveSlide;
            (function getNewSlide() {
                if (goToThisSlide == 0) return;
                $('#next').click();
                goToThisSlide--;
                getNewSlide();
            })();
        } else if (currActiveSlide > toConsultLink) {
            goToThisSlide = currActiveSlide - toConsultLink;
            (function getNewSlide() {
                if (goToThisSlide == 0) return;
                $('#prev').click();
                goToThisSlide--;
                getNewSlide();
            })();
        }


    })
    // Page slide logic end //

    //Slick slider//

    $('.gallery-slider-desktop').slick({
        dots: true,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 5,
        rows: 2,
        arrows: false,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    rows: 2,
                }
            },
        ]
    });

    $('.gallery-slider-mobile').slick({
        dots: true,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 5,
        rows: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    rows: 2,
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    rows: 1,
                    centerMode: true,
                    centerPadding: '20px',
                    slidesToShow: 1
                }
            }
        ]
    });

    // Contact Map start //

    var map = L.map('map', {
        minZoom: 8,
        maxZoom: 18,
    });
    var imgSrc = 'assets/img/map-marker.svg'
    var myIcon = L.divIcon({
        className: 'div-icon',
        html: "<img src='" + imgSrc + "' alt='icon'></div>",
    });
    map.setView([48.6195511, 22.2915002], 16);
    map.scrollWheelZoom.disable()
    L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
        attribution: '',
        id: 'mapbox/light-v9',
        tileSize: 512,
        zoomOffset: -1,
        accessToken: 'pk.eyJ1IjoidGFyYXNrZW5kem9yIiwiYSI6ImNrYXFtd3N2NzFxb3Yycm12Z3JucjY4eGsifQ.NsEAThUa-3Va7NGk0Kq7-Q'
    }).addTo(map);
    L.marker([48.619613, 22.293705], { icon: myIcon }).addTo(map);

    // Contact Map end //

    // Price pop-up start //

    $('#price-btn').on('click', function () {
        $('.pop-up-modal').fadeIn();
        $('.body-overlay').fadeIn();
    })

    $('.body-overlay, .close-pop-up-btn').on('click', function () {
        $('.pop-up-modal').fadeOut();
        $('.body-overlay').fadeOut();
    })

    // Price pop-up end //

    // Mobile Phone //

    $('.mobile-logo-btn').on('click', function (e) {
        e.stopPropagation();
        e.preventDefault();
        $('.phones-block').show();
        $(this).addClass('is-active');
    })

    $('body:not(.mobile-logo-btn)').on('click', function (e) {
        e.stopPropagation();
        // e.preventDefault();
        $('.phones-block').hide();
        $('.mobile-logo-btn').removeClass('is-active');
    });

    var currentX = '';
    var currentY = '';
    var movementConstant = .002;
    $('body').mousemove(function (e) {
        if (currentX == '') currentX = e.pageX;
        var xdiff = e.pageX - currentX;
        currentX = e.pageX;
        if (currentY == '') currentY = e.pageY;
        var ydiff = e.pageY - currentY;
        currentY = e.pageY;
        $('.background-img  img').each(function (i, el) {
            var movement = (i + 1) * (xdiff * movementConstant);
            var movementy = (i + 1) * (ydiff * movementConstant);
            var newX = $(el).position().left + movement;
            var newY = $(el).position().top + movementy;
            $(el).css('left', newX + 'px');
            $(el).css('top', newY + 'px');
        });
    });


    var currentX1 = '';
    var currentY1 = '';
    var movementConstant = .002;


})